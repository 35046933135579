import { useContext, useEffect } from "react";
import FooterComponent from "../components/Footer";
import HeaderComponent from "../components/Header";
import { AppContext } from "../contextapis/AppContext";
import NavigationComponent from "../components/Navigation";

const Vision11 = () => {
    const { light } = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'

    useEffect(() => {
        const scrollPosition = window.scrollY;
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, scrollPosition);
        };
    }, []);
    
    return (
        <>
            <NavigationComponent />
            <HeaderComponent title="Vision 11" />
            <div className="container-fluid feature pt-5">
                <div className="container pt-5">
                    <div className="col align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                        <h2 className={`mb-4 ${titleColor}`}>Keep your business up to speed</h2>
                        <p className={`mb-4 ${textColor}`}>
                            This product is a means for you to stay competitive in today’s rapidly changing business landscape. Vision 11 is like having a team of experts working around the clock to optimize your operations, improve your supply chain resilience, and drive innovation. By deploying advanced technologies in the production environment, Vision helps you achieve the highest levels of productivity.
                        </p>
                    </div>
                    <div className="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                        <img className="img-fluid" src="../src/img/feature.png" alt="" />
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}

export default Vision11;