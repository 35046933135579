import FooterComponent from "../components/Footer";
import HeaderComponent from "../components/Header";
import NavigationComponent from "../components/Navigation";
import { useContext } from "react";
import { AppContext } from "../contextapis/AppContext";

const FamilyScreenComponent = () => {
    const {light} = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'
    return (
        <div>
            <NavigationComponent />
            <HeaderComponent title="The Family" />
            <div className='container-fluid'>
                <div className="container">
                    <p className={`${titleColor}`}>The Lighthouse Family is a group of companies and individuals, across a broad range of industries, who are leading the way in Fourth Industrial Revolution innovation.
                        <p className={`mt-5 ${titleColor}`}>To join the Lighthouse Family, it is required that you achieve any of the following;</p>
                        <div className="container">
                        <ol>
                            <li>
                                <p className={`${titleColor}`}>Adopt Vision 11 as a module to monitor and promote operational efficiency </p>
                            </li>
                            <li>
                                <p className={`${titleColor}`}>Partake and complete any educational experience from the upcoming Lighthouse Academia</p>
                            </li>
                            <li>
                                <p className={`${titleColor}`}>Participate and accomplish significant advancements in science through the Lighthouse Research Institute</p>
                            </li>
                        </ol>
                        </div>
                    </p>
                </div>
            </div>
            <FooterComponent />
        </div>
    )
}

export default FamilyScreenComponent;