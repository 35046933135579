import { useContext, useEffect } from "react";
import FooterComponent from "../components/Footer";
import HeaderComponent from "../components/Header";
import { AppContext } from "../contextapis/AppContext";
import NavigationComponent from "../components/Navigation";
import { Link } from "react-router-dom";

const Sustainability = () => {
    const { light } = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'

    useEffect(() => {
        const scrollPosition = window.scrollY;
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, scrollPosition);
        };
    }, []);
    
    return (
        <>
            <NavigationComponent />
            <HeaderComponent title="Sustainability" />
            <div className="container-fluid feature pt-5">
                <div className="container pt-5">
                    <div className="col-lg-12 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                        <div>
                            <h2 className={`py-2 ${titleColor}`}>Definition</h2>
                            <p className={`${textColor}`}>Sustainability is the ability to maintain or support a process continuously over time. In business
                                and policy contexts, sustainability seeks to prevent the depletion of natural or physical resources,
                                so that they will remain available for the long term. Sustainability is often broken down into three
                                core concepts: economic, environmental, and social. Economic sustainability focuses on conserving
                                the natural resources that provide physical inputs for economic production, including both renewable
                                and exhaustible inputs. Environmental sustainability adds greater emphasis on the life support
                                systems, such as the atmosphere or soil, that must be maintained for economic production or human
                                life to even occur. Social sustainability focuses on the human effects of economic systems, and the
                                category includes attempts to eradicate poverty and hunger, as well as to combat inequality.</p>
                            <h2 className={`${titleColor}`}>Benefits</h2>
                            <p className={`${textColor}`}>Sustainability offers numerous benefits to companies that choose to incorporate it. Here are some
                                key benefits:</p>
                            <div>
                                <ul>
                                    <li>
                                        <h6 className={`${titleColor}`}>Increased efficiency and reduced costs</h6>
                                        <p className={`${textColor}`}>Sustainability initiatives can help companies identify inefficiencies in their operations
                                            and reduce costs. For example, reducing energy consumption can lead to lower utility
                                            bills, while optimizing supply chains can lead to reduced transportation costs.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Improved brand reputation</h6>
                                        <p className={`${textColor}`}>Companies that prioritize sustainability can improve their brand reputation and attract
                                            customers who value sustainable practices. This can lead to increased customer loyalty
                                            and higher sales.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Enhanced employee engagement</h6>
                                        <p className={`${textColor}`}>Sustainability initiatives can also improve employee engagement by creating a sense of
                                            purpose and pride in the company’s mission. This can lead to increased productivity,
                                            lower turnover rates, and a more positive work environment.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Reduced regulatory risk</h6>
                                        <p className={`${textColor}`}>Companies that prioritize sustainability are less likely to face regulatory fines or
                                            penalties for non-compliance with environmental or social regulations. This can help
                                            protect the company’s bottom line and reduce legal risks.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Access to new markets</h6>
                                        <p className={`${textColor}`}>Companies that prioritize sustainability can access new markets and customers who value
                                            sustainable practices. This can help the company expand its customer base and increase
                                            revenue.</p>
                                    </li>
                                </ul>
                            </div>
                            <h2 className={`py-2 ${titleColor}`}>Industry Applications</h2>
                            <p className={`${textColor}`}>Sustainability can be applied to a broad range of fields. Here are a few examples</p>
                            <div>
                                <ul>
                                    <li>
                                        <h6 className={`${titleColor}`}>Agriculture</h6>
                                        <p className={`${textColor}`}>Sustainability practices in agriculture can help reduce the use of natural resources and
                                            energy in production, processing, packaging, and distribution. This can include reducing
                                            waste and emissions by reducing chemical byproducts, as well as embedding sustainability
                                            criteria in procurement processes and project evaluation1.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Manufacturing</h6>
                                        <p className={`${textColor}`}>Sustainability practices in manufacturing can help reduce carbon emissions, water usage,
                                            e-waste, and chemical pollution. This can include using hybrid-electric equipment, fully
                                            electrifying operations, boosting efficiency through digitization and advanced
                                            analytics, and reorienting supply chains toward more circular practices2.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Transportation</h6>
                                        <p className={`${textColor}`}>Sustainability practices in transportation can help reduce carbon emissions and air
                                            pollution. This can include using electric or hybrid vehicles, optimizing supply chains
                                            to reduce transportation costs, and promoting public transportation3.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Construction</h6>
                                        <p className={`${textColor}`}>Sustainability practices in construction can help reduce carbon emissions and waste. This
                                            can include using sustainable building materials, optimizing energy efficiency in
                                            buildings, and reducing waste through recycling and reuse4.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Energy</h6>
                                        <p className={`${textColor}`}>Sustainability practices in the energy sector can help reduce carbon emissions and
                                            promote renewable energy sources. This can include investing in wind, solar, or
                                            hydroelectric power, optimizing energy efficiency in buildings and transportation, and
                                            reducing waste through recycling and reuse5.</p>
                                    </li>
                                </ul>
                            </div>
                            <h2 className={`py-2 ${titleColor}`}>Procedure</h2>
                            <p className={`${textColor}`}>Here is our procedure for incorporating sustainability across a company’s operations</p>
                            <div>
                                <ol>
                                    <li>
                                        <h6 className={`${titleColor}`}>Create a sustainability action plan</h6>
                                        <p className={`${textColor}`}>Commitment to sustainability should begin with the organization’s top executives and
                                            become integrated into the company’s vision and operations. The plan should include
                                            specific goals, targets, and timelines for reducing the company's environmental impact
                                            and improving social responsibility. It should also include a system for measuring and
                                            reporting progress toward these goals.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Engage stakeholders</h6>
                                        <p className={`${textColor}`}>Engage employees, customers, suppliers, and other stakeholders in the sustainability
                                            effort. This can include setting up a sustainability committee or task force to oversee
                                            the implementation of the sustainability action plan. It can also include creating a
                                            system for soliciting feedback from stakeholders and incorporating their input into the
                                            sustainability strategy.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Integrate sustainability into business processes</h6>
                                        <p className={`${textColor}`}>Integrate sustainability considerations into all aspects of the business, including
                                            procurement, production, distribution, and marketing. This can include setting up a
                                            system for evaluating suppliers based on their environmental and social performance, as
                                            well as incorporating sustainability criteria into product design and development.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Invest in sustainable technologies</h6>
                                        <p className={`${textColor}`}>Invest in sustainable technologies that can help reduce the company's environmental
                                            impact and improve operational efficiency. This can include investing in renewable
                                            energy sources, energy-efficient equipment, and waste reduction technologies.</p>
                                    </li>
                                    <li>
                                        <h6 className={`${titleColor}`}>Measure and report progress</h6>
                                        <p className={`${textColor}`}>Measure and report progress toward sustainability goals on a regular basis. This can
                                            include tracking key performance indicators (KPIs) such as energy consumption,
                                            greenhouse gas emissions, waste generation, and water usage. It can also include
                                            publishing an annual sustainability report to communicate progress to stakeholders.</p>
                                    </li>
                                </ol>
                            </div>
                            <h2 className={`py-2 ${titleColor}`}>Incorporate Sustainability across your Operations</h2>
                            <p className={`${textColor}`}>What sustainability goals has your company set? What steps has your company taken to improve its environmental impact? How does your company engage with stakeholders on sustainability issues? Get started with a
                                free consultancy call. We`d be more than happy to chat with you</p>
                            <Link to="#" data-bs-toggle="modal" data-bs-target="#scheduleModal"
                                className="btn btn-outline-dark rounded-pill animated slideInRight my-4 ">Schedule
                                Call</Link>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}

export default Sustainability;