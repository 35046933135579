import { useContext, useEffect } from "react";
import FooterComponent from "../components/Footer";
import HeaderComponent from "../components/Header";
import { AppContext } from "../contextapis/AppContext";
import NavigationComponent from "../components/Navigation";
import { Link } from "react-router-dom";

const Automation = () => {
    const { light } = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'
    const buttonOutline = light ? 'btn-outline-dark' : 'btn-outline-light'

    useEffect(() => {
        const scrollPosition = window.scrollY;
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, scrollPosition);
        };
    }, []);

    return (
        <>
            <NavigationComponent />
            <HeaderComponent title="Process Automation" />
            <div className="container-fluid feature pt-5">
                <div className="container pt-5">
                    <div className="col-lg-12 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                        <div>
                            <h2 className={`py-2 ${titleColor}`}>Definition</h2>
                            <p className={`${textColor}`}>Process automation is the use of software and technologies to
                                automate business processes and functions. It may involve robotic automation, or some other form of automation</p>
                            <h2 className={`${titleColor}`}>Benefits</h2>
                            <p className={`${textColor}`}>Process automation offers numerous benefits to companies that choose to adopt it. Here are some
                                key benefits:</p>
                            <div>
                                <h6 className={`${titleColor}`}>Increased Efficiency:</h6>
                                <p className={`${textColor}`}>Automation eliminates time-consuming manual tasks, allowing processes to
                                    be completed faster and with greater accuracy. This leads to increased
                                    efficiency and productivity within the organization.</p>

                                <h6 className={`${titleColor}`}>Cost Savings:</h6>
                                <p className={`${textColor}`}>By automating repetitive and labor-intensive tasks, companies can reduce
                                    their reliance on human resources, resulting in cost savings. Automation
                                    also helps minimize errors and rework, further reducing operational
                                    costs.</p>

                                <h6 className={`${titleColor}`}>Improved Accuracy:</h6>
                                <p className={`${textColor}`}>Robotic automation ensures consistent and error-free execution of tasks.
                                    By removing the potential for human error, companies can achieve higher
                                    levels of accuracy and quality in their processes.</p>

                                <h6 className={`${titleColor}`}>Enhanced Scalability:</h6>
                                <p className={`${textColor}`}>Automated processes can handle large volumes of work without the need for
                                    additional human resources. This scalability allows companies to handle
                                    increased workloads and easily adapt to changing business needs.</p>

                                <h6 className={`${titleColor}`}>Streamlined Workflows:</h6>
                                <p className={`${textColor}`}>Process automation optimizes workflows by eliminating bottlenecks,
                                    reducing unnecessary steps, and ensuring smooth handoffs between tasks
                                    and departments. This streamlining improves overall process flow and
                                    enhances collaboration.</p>

                                <h6 className={`${titleColor}`}>Faster Turnaround Times:</h6>
                                <p className={`${textColor}`}>Automation accelerates the pace of business processes, enabling faster
                                    turnaround times for customer requests, order fulfillment, and other
                                    critical operations. This can lead to improved customer satisfaction and
                                    increased competitiveness.</p>

                                <h6 className={`${titleColor}`}>Regulatory Compliance:</h6>
                                <p className={`${textColor}`}>Automated processes can help ensure compliance with industry regulations
                                    and internal policies. By enforcing standardized procedures and audit
                                    trails, companies can mitigate risks and demonstrate adherence to
                                    compliance requirements.</p>

                                <h6 className={`${titleColor}`}>Data-driven Insights:</h6>
                                <p className={`${textColor}`}>Automation generates valuable data as processes are executed. By
                                    analyzing this data, companies can gain actionable insights into their
                                    operations, identify areas for improvement, and make informed business
                                    decisions.</p>

                                <p className={`${textColor}`}>By leveraging process automation, companies can unlock these benefits and
                                    gain a competitive edge in today's fast-paced business environment.</p>
                                <p className={`${textColor}`}>You can never go wrong by automating repetitive tasks</p>
                            </div>
                            <h2 className={`py-2 ${titleColor}`}>Industry Applications</h2>
                            <p className={`${textColor}`}>Automation can be applied to a broad range of fields. Here are a few examples</p>
                            <div>
                                <h6 className={`${titleColor}`}>Data Entry:</h6>
                                <p className={`${textColor}`}>Robotic Process Automation (RPA) can be used to automate the manual entry of
                                    data from one system to another, reducing errors and freeing up human
                                    resources for more complex tasks.</p>

                                <h6 className={`${titleColor}`}>Invoice Processing:</h6>
                                <p className={`${textColor}`}>RPA can scan invoices, extract relevant information such as invoice numbers
                                    and amounts, and input them into accounting systems, reducing the time and
                                    effort required for manual data entry.</p>

                                <h6 className={`${titleColor}`}>Report Generation:</h6>
                                <p className={`${textColor}`}>Automated bots can gather data from various sources, compile reports, and
                                    distribute them to relevant stakeholders on a scheduled basis, eliminating
                                    the need for manual report generation.</p>

                                <h6 className={`${titleColor}`}>Inventory Management:</h6>
                                <p className={`${textColor}`}>Robots can monitor inventory levels, generate reorder requests, and even
                                    autonomously place orders with suppliers, ensuring optimal stock levels and
                                    reducing the risk of stockouts or excess inventory.</p>

                                <h6 className={`${titleColor}`}>Customer Service:</h6>
                                <p className={`${textColor}`}>Chatbots and virtual assistants powered by artificial intelligence (AI) can
                                    handle customer inquiries, provide basic support, and assist with tasks such
                                    as order tracking or account management, freeing up human agents for more
                                    complex customer issues.</p>

                                <h6 className={`${titleColor}`}>Quality Control:</h6>
                                <p className={`${textColor}`}>Robots equipped with computer vision systems can inspect products or
                                    components for defects, ensuring consistent quality and reducing the need
                                    for manual inspection.</p>

                                <h6 className={`${titleColor}`}>IT Operations:</h6>
                                <p className={`${textColor}`}>RPA can be used to automate routine IT tasks such as user provisioning,
                                    password resets, and system maintenance, reducing the workload on IT staff.
                                </p>

                                <h6 className={`${titleColor}`}>HR Onboarding:</h6>
                                <p className={`${textColor}`}>Robotic automation can streamline the employee onboarding process by
                                    automatically generating offer letters, initiating background checks, and
                                    setting up new employee accounts in various systems.</p>

                                <h6 className={`${titleColor}`}>Financial Reconciliation:</h6>
                                <p className={`${textColor}`}>RPA can compare financial data from multiple sources, identify discrepancies,
                                    and perform automated reconciliations, reducing the time and effort required
                                    for manual reconciliation processes.</p>

                                <h6 className={`${titleColor}`}>Supply Chain Management:</h6>
                                <p className={`${textColor}`}>Robots can track shipments, update inventory systems, and optimize logistics
                                    routes, improving the efficiency and accuracy of supply chain operations.
                                </p>

                            </div>
                            <h2 className={`py-2 ${titleColor}`}>Procedure</h2>
                            <p className={`${textColor}`}>Below is our procedure for building automated processes</p>
                            <div>
                                <ol>
                                    <li>
                                        <h6 className={`${titleColor}`}>Identify the Process:</h6>
                                        <p className={`${textColor}`}>Determine the specific process or task that is suitable for automation.
                                            Consider factors
                                            such as its frequency, complexity, and potential for efficiency gains.
                                        </p>
                                    </li>

                                    <li>
                                        <h6 className={`${titleColor}`}>Analyze the Process:</h6>
                                        <p className={`${textColor}`}>Thoroughly examine the existing process, documenting each step, inputs,
                                            outputs, and
                                            dependencies. Identify areas that can be automated and potential
                                            challenges or
                                            bottlenecks.</p>
                                    </li>

                                    <li>
                                        <h6 className={`${titleColor}`}>Design the Automation Workflow:</h6>
                                        <p className={`${textColor}`}>Create a detailed plan for the automated workflow. Define the sequence of
                                            tasks, decision
                                            points, data inputs, and expected outputs. Consider integration with
                                            existing systems or
                                            software.</p>
                                    </li>

                                    <li>
                                        <h6 className={`${titleColor}`}>Select Automation Tools:</h6>
                                        <p className={`${textColor}`}>Choose the appropriate automation tools or software based on the
                                            requirements of the
                                            process. Evaluate options such as Robotic Process Automation (RPA),
                                            workflow management
                                            systems, or custom scripting.</p>
                                    </li>

                                    <li>
                                        <h6 className={`${titleColor}`}>Develop and Test the Automation:</h6>
                                        <p className={`${textColor}`}>Develop the automation solution according to the designed workflow.
                                            Create scripts,
                                            configure the automation software, or build custom applications. Test
                                            the automation
                                            thoroughly to ensure accuracy and reliability.</p>
                                    </li>

                                    <li>
                                        <h6 className={`${titleColor}`}>Implement the Automation:</h6>
                                        <p className={`${textColor}`}>Deploy the automation solution into the production environment. Ensure
                                            that all necessary
                                            permissions, access rights, and dependencies are in place. Train
                                            relevant personnel on
                                            how to operate and monitor the automation.</p>
                                    </li>

                                    <li>
                                        <h6 className={`${titleColor}`}>Monitor and Maintain:</h6>
                                        <p className={`${textColor}`}>Regularly monitor the automated process to ensure its smooth functioning
                                            and detect any
                                            issues or exceptions. Maintain the automation by updating scripts or
                                            configurations as
                                            needed and addressing any changes in the underlying systems.</p>
                                    </li>

                                    <li>
                                        <h6 className={`${titleColor}`}>Continuously Improve:</h6>
                                        <p className={`${textColor}`}>Seek opportunities for further optimization and enhancement of the
                                            automated process.
                                            Analyze data and feedback to identify areas of improvement and implement
                                            iterative
                                            changes to maximize efficiency and effectiveness.</p>
                                    </li>
                                </ol>
                                <p className={`${textColor}`}>Following these key steps will help ensure a systematic and successful
                                    implementation of process
                                    automation within an organization.</p>
                            </div>
                            <h2 className={`py-2 ${titleColor}`}>Improve your Operations with Process Automation</h2>
                            <p className={`${textColor}`}>Are there company processes that are repetitive and tiring? Can we automate such processes and provide room for more intensive tasks? Get started
                                with a free consultancy call. We`d be happy to chat with you</p>
                            <Link to="#" className={`btn ${buttonOutline} rounded-pill animated slideInRight my-4`}>Schedule
                                Call</Link>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}

export default Automation;