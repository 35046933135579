import HeaderComponent from "../components/Header";
import NavigationComponent from "../components/Navigation";
import FooterComponent from "../components/Footer";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../contextapis/AppContext";

const ProductScreenComponent = () => {
    const { light } = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'
    return (
        <div>
            <NavigationComponent />
            <HeaderComponent title="Products" />
            <div className="container-fluid py-5 my-5">
                <div className="container mb-4">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div className="btn btn-sm text-gold rounded-pill px-3 mb-3">Enterprise</div>
                            <h1 className={`mb-1 ${titleColor}`}>Vision 11</h1>
                            <p className={`mb-5 ${textColor}`}>Keep up with emerging technologies
                            </p>
                            <p className={`${textColor}`}>Vision 11 is here to help you navigate the fourth industrial revolution.
                                Keep your operations model up to speed, leverage data to the fullest, build intelligent systems, and automate repetitive tasks. Vision is a friend to those who desire growth </p>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="px-5 ">
                                {
                                    light ? (
                                        <img src={require("../img/Vision-11.png")} className="img-fluid" alt="Vision-11-Logo" />
                                    ) : (
                                        <img src={require("../img/Vision-11-dark.png")} className="img-fluid" alt="Vision-11-Logo" />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <Link to="/products/vision-11" className='btn btn-dark py-sm-3 px-sm-5 rounded-pill animated slideInRight mt-5'>Discover Today</Link>
                </div>
                <div className="container py-5 my-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div className="btn btn-sm text-gold rounded-pill px-3 mb-3">Enterprise</div>
                            <h1 className={`mb-1 ${titleColor}`}>Alma</h1>
                            <p className={`mb-5 ${textColor}`}>Online Business Toolkit
                            </p>
                            <p className={`${textColor}`}>Alma is an enterprise management software that helps businesses tap into the online market.
                                With Alma, you get access to exposure, data analytics, and an Assistant for your business. Download the Alma App Today.</p>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="px-5 ">
                                <img src={require("../img/alma-1.jpg")} className="img-fluid" alt="Alma-Logo" />
                            </div>
                        </div>
                    </div>
                    <Link to="/products/alma" className='btn btn-dark py-sm-3 px-sm-5 rounded-pill animated slideInRight mt-5'>Discover Today</Link>
                </div>
                <div className="container py-5 my-5">
                    <div className="row g-5 mb-4">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div className="btn btn-sm text-gold rounded-pill px-3 mb-3">Education</div>
                            <h1 className={`mb-1 ${titleColor}`}>Lighthouse Academia</h1>
                            <p className={`mb-5 ${textColor}`}>Revolutionizing the education system
                            </p>
                            <p className={`${textColor}`}>With the ever growing adoption of emerging technologies,
                                arises the need for robust and flexible education systems. Avenues for learning and
                                growth that match industry needs and expectations. Speed and efficiency are key factors
                                when designing an education system, and were put into consideration when designing Lighthouse Academia</p>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="px-5 ">
                                {
                                    light ? (
                                        <img src={require("../img/academia.png")} className="img-fluid" alt="LRI-Logo" />
                                    ) : (
                                        <img src={require("../img/academia-dark.png")} className="img-fluid" alt="LRI-Logo" />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <Link to="/products/academia" className='btn btn-dark py-sm-3 px-sm-5 rounded-pill animated slideInRight mt-5'>Discover Today</Link>
                </div>
                <div className="container py-5 my-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div className="btn btn-sm text-gold rounded-pill px-3 mb-3">Research</div>
                            <h1 className={`mb-1 ${titleColor}`}>Lighthouse Research Insitute</h1>
                            <p className={`mb-5 ${textColor}`}>Advancing Human Capabilities
                            </p>
                            <p className="text-white">From advancing the human brain, to aiding technological advancements, LRI is the hub of the future. Cutting across Bioinformatics, Materials Science and numerous engineering fields, we explore the most productive and best way forward</p>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="px-5 ">
                                {
                                    light ? (
                                        <img src={require("../img/LRI-light.png")} className="img-fluid" alt="LRI-Logo" />
                                    ) : (
                                        <img src={require("../img/LRI-dark.png")} className="img-fluid" alt="LRI-Logo" />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <Link to="/solutions/research" className='btn btn-dark py-sm-3 px-sm-5 rounded-pill animated slideInRight mt-5'>Discover Today</Link>
                </div>
            </div>
            <FooterComponent />
        </div>
    )
}

export default ProductScreenComponent;