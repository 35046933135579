import { useContext, useEffect } from "react";
import FooterComponent from "../components/Footer";
import HeaderComponent from "../components/Header";
import { AppContext } from "../contextapis/AppContext";
import NavigationComponent from "../components/Navigation";

const Research = () => {
    const { light } = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'

    useEffect(() => {
        const scrollPosition = window.scrollY;
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, scrollPosition);
        };
    }, []);
    
    return (
        <>
            <NavigationComponent />
            <HeaderComponent title="Research & Innovation" />
            <div className="container-fluid feature pt-5">
                <div className="container pt-5">
                    <div className="col align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                        <h1 className={`mb-4 ${titleColor}`}>Advancing Human Capabiliies</h1>
                        <p className={`mb-4 ${textColor}`}>
                            Welcome to LRI, the hub of the future.
                            We explore mathematical models that can improve engineering and automation
                            capabilities. From BioInformatics to sustainable energy, we aim to solve
                            humanity`s biggest problems with the power of mathematics.
                            LRIs biggest project is curing aging, which we believe we can achieve
                            by 2040.
                        </p>
                        <div className="d-flex align-items-center mb-3 mt-3">
                            <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                <i className="fa fa-check"></i>
                            </div>
                            <span className={`${textColor}`}>Net positive Impact</span>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                <i className="fa fa-check"></i>
                            </div>
                            <span className={`${textColor}`}>Proof of concept</span>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                <i className="fa fa-check"></i>
                            </div>
                            <span className={`${textColor}`}>Desire to implement</span>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                        <img className="img-fluid" src="../../img/feature.png" alt="" />
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}

export default Research;