import {useContext} from 'react';
import HomeScreenComponent from './screens/HomeScreen';
import ProductScreenComponent from './screens/ProductScreen';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FourScreenComponent from './screens/FourScreen';
import ContactScreenComponent from './screens/ContactScreen';
import "../src/css/global-styles.css"
import "../src/css/styles.css"
import FamilyScreenComponent from './screens/FamilyScreen';
import Academia from './products/Academia';
import Research from './products/Research';
import Vision11 from './products/Vision-11';
import Automation from './module/Automation';
import Science from './module/Science';
import Data from './module/Data';
import Sustainability from './module/Sustainability';
import Alma from './products/Alma';
import { AppContext } from './contextapis/AppContext';

function App() {
  const {light} = useContext(AppContext)
  const AppBackground = light ? 'light' : 'dark'
  const Overlay = light ? 'light-overlay' : 'dark-overlay'
  return (
    <div className={AppBackground}>
      <div className={Overlay}>
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreenComponent />} />
          <Route path="/products" element={<ProductScreenComponent />} />
          <Route path="/products/vision-11" element={<Vision11 />} />
          <Route path="/products/vision-11/automation" element={<Automation />} />
          <Route path="/products/vision-11/science" element={<Science />} />
          <Route path="/products/vision-11/data" element={<Data />} />
          <Route path="/products/vision-11/sustainability" element={<Sustainability />} />
          <Route path="/products/alma" element={<Alma />} />
          <Route path="/products/academia" element={<Academia />} />
          <Route path="/products/research" element={<Research />} />
          <Route path="/family" element={<FamilyScreenComponent />} />
          <Route path="/contact" element={<ContactScreenComponent />} />
          <Route path="*" element={<FourScreenComponent />} />
        </Routes>
      </Router>
      </div>
    </div>
  );
}

export default App;
