import { useContext, useEffect } from "react";
import FooterComponent from "../components/Footer";
import HeaderComponent from "../components/Header";
import { AppContext } from "../contextapis/AppContext";
import NavigationComponent from "../components/Navigation";
import { Link } from "react-router-dom";

const Academia = () => {
    const { light } = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'
    const buttonOutline = light ? 'btn-outline-dark' : 'btn-outline-light'

    useEffect(() => {
        const scrollPosition = window.scrollY;
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, scrollPosition);
        };
    }, []);

    return (
        <>
            <NavigationComponent />
            <HeaderComponent title="Academia Plus" />
            <div className="container-fluid pt-5">
                <div className="container pt-5">
                    <div className="col align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                        <h1 className={`mb-4 ${titleColor}`}>Lighthouse Academia</h1>
                        <h2 className={`my-5 ${titleColor}`}>Introduction</h2>
                        <p className={`mb-4 ${textColor}`}>
                            At the core of every society is education. Education has the ability to not only transform an individual,
                            but entire nations and civilizations. A high correlation exists between socio-economic status, and the degree
                            to which individuals of a society have been educated. Kenya, among other nations, owes its current socio-economic status to the status of its education system. Kindergaten, primary school, and secondary school serve as a basis for foundational thinking. They are the building blocks of creative prowess, and problem solving, and Kenya has done a good job to promote and enhance the quality of these three levels. However, the fourth industrial revolution is here, and is calling on the tertiary system to grow and adapt. Countries and societies that heed to this call are more likely to grow and evolve, leaving behind those that do not. As a Kenyan Corporation, we are drawn towards realizing the former, and working to ensure that Kenya, and Africa as a continent, utilizes the fourth revolution as a catapult towards socio-economic prosperity.
                        </p>
                        <p className={`${textColor}`}>Research Timeline: 6 months</p>
                        <h1 className={`my-5 ${titleColor}`}>Hypothesis</h1>
                        <p className={`${textColor}`}>It is possible to transform Kenya into a first world country through education and guided innovation</p>
                        <h1 className={`my-5 ${titleColor}`}>How?</h1>
                        <p className={`${textColor}`}>We have put together a research program, that aims to identify key aspects of the fourth industrial revolution. The research program will answer the following questions; </p>
                        <ol>
                            <li className={`${textColor} my-4`}>What is Kenya's current production model, and how does it compare to other countries?</li>
                            <li className={`${textColor} my-4`}>What are some emerging technologies that Kenya should invest in as soon as possible?</li>
                            <li className={`${textColor} my-4`}>To what degree can we adjust this production model to ensure Kenya keeps up with the fourth industrial revolution and this emerging technologies?</li>
                            <li className={`${textColor} my-4`}>How do we implement a data-driven education curriculum that realizes this model?</li>
                            <li className={`${textColor} my-4`}>How do we optimize the speed and efficiency of this curriculum?</li>
                            <li className={`${textColor} my-4`}>How do we stimulate Kenyans to engage in this curriculum and participate in the newly formed production model?</li>
                        </ol>
                        <p className={`${textColor}`}>Some answers to this questions may seem obvious, with technologies such as Artificial Intelligence and Bio-Engineering at the forefront of this curriculum. However, when the state of millions of lives is at stake, it is imperative that we develop a data-driven approach. The research will involve hands on, industry standard data analytics, towards the realization of this goal</p>
                        <h1 className={`my-5 ${titleColor}`}>Value Proposition</h1>
                        <p className={`${textColor}`}>Why is this research program different? To what extent will it transform our nation, and other nations that adopt its findings? </p>
                        <p className={`${textColor}`}>This research aims to utilize the modern bootcamp system, which delivers industry standard education in months, as an engine for nationwide growth.</p>
                        <p className={`${textColor}`}>Since the approach is a data-driven one, it has hight prospects for solving unemployment.</p>
                        <p className={`${textColor}`}>We strongly believe this is the solution to transforming our nation.</p>

                        <div className="d-flex align-items-center mt-5">
                            <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                <i className="fa fa-check"></i>
                            </div>
                            <span className={`${textColor}`}>Net positive Impact</span>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                <i className="fa fa-check"></i>
                            </div>
                            <span className={`${textColor}`}>Proof of concept</span>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                <i className="fa fa-check"></i>
                            </div>
                            <span className={`${textColor}`}>Desire to implement</span>
                        </div>
                        <div>
                            <h1 className={`my-5 ${titleColor}`}>Research Requirements</h1>
                            <p className={`${textColor}`}>
                                The program requires 300M Kenyan shillings as grant offering. The donating party will receive;
                                <ol>
                                    <li className={`${textColor} my-4`}>A copy of the research findings</li>
                                    <li className={`${textColor} my-4`}>Permission to implement the findings</li>
                                    <li className={`${textColor} my-4`}>Entry into the Lighthouse Family</li>
                                    <li className={`${textColor} my-4`}>Lifetime ability to contribute towards other research programs</li>
                                </ol>
                            </p>
                        </div>
                        <div>
                            <h1 className={`my-5 ${titleColor}`}>Suitable beneficiaries and investors</h1>
                            <ol>
                                <li className={`${textColor} my-4`}>The Government of Kenya</li>
                                <li className={`${textColor} my-4`}>Public Organizations interested in the growth of Kenya</li>
                                <li className={`${textColor} my-4`}>Private Organizations interested in the growth of Kenya</li>
                            </ol>
                        </div>
                        <div>
                            <h1 className={`my-5 ${titleColor}`}>Reach out</h1>
                            <p className={`${textColor}`}>Would you like to invest in Kenya's growth? Call us today.</p>
                            <Link to="#" className={`btn ${buttonOutline} rounded-pill animated slideInRight my-4`}>Schedule
                                Call</Link>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                        <img className="img-fluid" src="../../img/feature.png" alt="" />
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}

export default Academia;