import { useContext, useEffect } from "react";
import FooterComponent from "../components/Footer";
import HeaderComponent from "../components/Header";
import { AppContext } from "../contextapis/AppContext";
import NavigationComponent from "../components/Navigation";
import { Link } from "react-router-dom";

const Science = () => {
    const { light } = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'
    const buttonOutline = light ? 'btn-outline-dark' : 'btn-outline-light'

    useEffect(() => {
        const scrollPosition = window.scrollY;
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, scrollPosition);
        };
    }, []);

    return (
        <>
            <NavigationComponent />
            <HeaderComponent title="Science & Engineering" />
            <div className="container-fluid feature pt-5">
                <div className="container pt-5">
                    <div className="col-lg-12 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                        <div>
                            <h2 className={`py-2 ${titleColor}`}>Introduction</h2>
                            <p className={`${textColor}`}>Science and engineering principles are crucial for improving business operations. They provide the
                                necessary tools to optimize processes, reduce costs, and increase efficiency. Operations research
                                uses mathematical models to analyze complex systems and make data-driven decisions. Engineering
                                principles can be applied to improve the design of products and services, making them more efficient
                                and cost-effective. Science and engineering principles help businesses stay competitive by enabling
                                them to develop new products and services that meet changing customer needs.</p>
                            <h2 className={`${titleColor}`}>Benefits</h2>
                            <p className={`${textColor}`}>Science and engineering principles offer numerous benefits to companies that choose to adopt them.
                                Here are some
                                key benefits:</p>
                            <div>
                                <ul>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Optimized processes:</h4><p className={`${textColor}`}> They provide the necessary tools and
                                            techniques to optimize processes, reduce costs, and increase efficiency.
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Data-driven decisions:</h4><p className={`${textColor}`}> Operations research uses mathematical models to analyze
                                            complex systems and make data-driven decisions.
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Improved product design:</h4><p className={`${textColor}`}> Engineering principles can be applied to improve the
                                            design of products and services, making them more efficient and cost-effective.
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Innovation:</h4><p className={`${textColor}`}> Science and engineering principles help businesses stay competitive by
                                            enabling
                                            them to develop new products and services that meet changing customer needs.
                                        </p></li>
                                </ul>
                            </div>
                            <h2 className={`py-2 ${titleColor}`}>Industry Applications</h2>
                            <p className={`${textColor}`}>Science and Engineering can be applied to a broad range of fields. Here are a few examples</p>
                            <div>
                                <ul>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Biotechnology:</h4><p className={`${textColor}`}> Biotechnology has applications in four major industrial areas,
                                            including health care (medical), crop production and agriculture, non-food (industrial) uses
                                            of crops and other products (e.g., biodegradable plastics, vegetable oil, biofuels), and
                                            environmental uses.
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Civil Engineering:</h4><p className={`${textColor}`}> Civil engineers can use artificial intelligence to identify
                                            safety hazards from site CCTV, reactive construction scheduling, off-site construction
                                            component assembly simulation, remote inspection using virtual reality (VR), and optimized
                                            labor management.
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Data Science:</h4><p className={`${textColor}`}> Data science has applications in various industries such as finance,
                                            healthcare, retail, manufacturing, and more. It can be used to optimize supply chain
                                            management, improve customer experience, reduce costs, and increase efficiency.
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Electrical and Computer Engineering:</h4><p className={`${textColor}`}> Electrical and computer engineers work at the
                                            forefront of technological innovation, contributing to the design, development, testing, and
                                            manufacturing processes for new generations of devices and equipment.
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Machine Learning:</h4><p className={`${textColor}`}> Machine learning allows companies to automate a wide variety of
                                            tasks, making them more efficient and cost-effective. It has applications in various
                                            industries such as healthcare, finance, retail, manufacturing, and more.
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Operations Research:</h4><p className={`${textColor}`}> Operations research is a field of study that uses mathematical
                                            models to analyze complex systems and make data-driven decisions. It can be used to optimize
                                            supply chain management, production scheduling, inventory control, and more.
                                        </p></li>
                                </ul>
                            </div>
                            <h2 className={`py-2 ${titleColor}`}>Procedure</h2>
                            <p className={`${textColor}`}>Here is our procedure for incorporating science and engineering across operations</p>
                            <div>
                                <ol>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Define the scope of the project:</h4><p className={`${textColor}`}>
                                            <p className={`${textColor}`}>Identify the areas of your business that require scientific and engineering expertise.
                                                This will help you determine the scope of your project.</p>
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Identify the scientific and engineering skills required:</h4><p className={`${textColor}`}>
                                            <p className={`${textColor}`}>Determine the specific skills that are required to achieve your goals. This will help you
                                                identify the right people to work on your project.</p>
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Develop a plan:</h4><p className={`${textColor}`}>
                                            <p className={`${textColor}`}>Create a plan that outlines how you will incorporate science and engineering into your
                                                business operations. This plan should include timelines, budgets, and resources
                                                required.</p>
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Implement the plan:</h4><p className={`${textColor}`}>
                                            <p className={`${textColor}`}>Once you have a plan in place, it's time to start implementing it. This may involve
                                                hiring new staff, training existing staff, or outsourcing work to third-party providers.
                                            </p>
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Monitor progress:</h4><p className={`${textColor}`}>
                                            <p className={`${textColor}`}>Keep track of your progress and make adjustments as necessary. This will help you ensure
                                                that your project is on track and that you are achieving your goals.</p>
                                        </p></li>
                                    <li>
                                        <h4 className={`my-4 ${titleColor}`}>Evaluate results:</h4><p className={`${textColor}`}>
                                            <p className={`${textColor}`}>Once your project is complete, evaluate the results to determine whether it was
                                                successful. Use this information to make improvements for future projects.</p>
                                        </p></li>
                                </ol>
                            </div>
                            <h2 className={`py-2 ${titleColor}`}>Incorporate Science and Engineering across your Operations</h2>
                            <p className={`${textColor}`}>What scientific and engineering expertise do you have in-house? How do you integrate scientific and
                                engineering knowledge into your business operations? What scientific and engineering projects have
                                you completed in the past? Get started
                                with a free consultancy call. We`d be more than happy to chat with you</p>
                            <Link to="#" className={`btn ${buttonOutline} rounded-pill animated slideInRight my-4`}>Schedule
                                Call</Link>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}

export default Science;