import NavigationComponent from "../components/Navigation";
import HeaderComponent from "../components/Header";
import FooterComponent from "../components/Footer";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../contextapis/AppContext";

const HomeScreenComponent = () => {
    const {light} = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'
    const buttonOutline = light ? 'btn-outline-dark' : 'btn-outline-light'
    return (
        <div>
            <NavigationComponent />
            <HeaderComponent title="Advancing Humanity" />
            {/* About */}
            <div className="container-fluid custom-container my-5" id="about">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="">
                                <img src={require("../img/lighthouse-plain-light.png")} className="img-fluid" alt="Lighthouse Logo" />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div className="btn btn-sm border text-gold rounded-pill px-3 mb-3">About Us</div>
                            <h1 className={`mb-4 ${titleColor}`}>What we do</h1>
                            <p className={`mb-4 ${textColor}`}> The Fourth Industrial Revolution is here.</p>
                            <p className={`mb-4 ${textColor}`}>A remarkable gap will exist between companies that adopt 4th revolution technologies and those that do not.
                            </p>
                            <p className={`${textColor}`}>Our goal as Lighthouse, is to illuminate this new era of innovation</p>
                            <div className="d-flex align-items-center mt-4">
                                <Link className={`btn ${buttonOutline} btn-square me-3 `} to="#"><i className="fab fa-facebook-f"></i></Link>
                                <Link className={`btn ${buttonOutline} btn-square me-3 `} to="#"><i className="fab fa-twitter"></i></Link>
                                <Link className={`btn ${buttonOutline} btn-square me-3 `} to="#"><i className="fab fa-instagram"></i></Link>
                                <Link className={`btn ${buttonOutline} btn-square me-3 `} to="#"><i className="fab fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Module */}
            <div className="container-fluid py-5 align-items-center">
                <div className=" container py-5 align-items-center">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="btn btn-sm border text-gold rounded-pill px-3 mb-3">Products
                            </div>
                            <h1 className={`mb-4 ${titleColor}`}>Vision 11</h1>
                            <p className={`mb-4 ${textColor}`}>Stay up to speed with emerging technologies using Vision 11</p>
                            <Link to="/products/vision-11" className='btn btn-dark py-sm-3 px-sm-5 rounded-pill animated slideInRight mt-5'>Dive Deeper</Link>
                        </div>
                        <div className="col-lg-7">
                            <div className="row g-4">
                                <div className="col-md-6 pt-md-4">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                                            {/* Data Analytics */}
                                            <div
                                                className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                    <i className="fa fa-power-off fa-2x"></i>
                                                </div>
                                                <h5 className="mb-3">Data</h5>
                                                <p>
                                                    Leverage Data to improve operations
                                                </p>
                                                <Link to="/products/vision-11/data" className="btn px-3 mt-auto mx-auto">Read More</Link>
                                            </div>
                                        </div>
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                                            {/* Process Automation */}
                                            <div
                                                className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                    <i className="fa fa-graduation-cap fa-2x"></i>
                                                </div>
                                                <h5 className="mb-3">Automation</h5>
                                                <p>Automate repetitive tasks</p>
                                                <Link to="/products/vision-11/automation" className="btn px-3 mt-auto mx-auto">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" id="strongholds">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                            {/* Sustainability */}
                                            <div
                                                className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                    <i className="fa fa-brain fa-2x"></i>
                                                </div>
                                                <h5 className="mb-3">Sustainability</h5>
                                                <p>
                                                    Promote green operations
                                                </p>
                                                <Link to="/products/vision-11/sustainability" className="btn px-3 mt-auto mx-auto">Read More</Link>
                                            </div>
                                        </div>
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                            {/* Science and Engineering */}
                                            <div
                                                className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                    <i className="fa fa-robot fa-2x"></i>
                                                </div>
                                                <h5 className="mb-3">Science and Engineering</h5>
                                                <p>Leverage science to improve operations
                                                </p>
                                                <Link to="/products/vision-11/science" className="btn px-3 mt-auto mx-auto">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Family */}
            <div className="container-fluid feature pt-5" id="community">
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                            <div className="btn btn-sm border text-gold rounded-pill px-3 mb-3">Family</div>
                            <h1 className={`${titleColor} mb-4`}>When we work together, we grow together</h1>
                            <p className={`${textColor} mb-4`}>Join the Lighthouse Family and harness the power of connectivity.
                            </p>
                            <div className={`d-flex align-items-center ${textColor} mb-3`}>
                                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                    <i className="fa fa-check"></i>
                                </div>
                                <span className={`${textColor}`}>Innovation</span>
                            </div>
                            <div className={`d-flex align-items-center ${textColor} mb-3`}>
                                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                    <i className="fa fa-check"></i>
                                </div>
                                <span className={`${textColor}`}>Productivity</span>
                            </div>
                            <div className={`d-flex align-items-center ${textColor} mb-3`}>
                                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                                    <i className="fa fa-check"></i>
                                </div>
                                <span className={`${textColor}`}>Growth</span>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <img className="img-fluid" src={require("../img/abh.png")} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            {/* More Products */}
            <div className="container-fluid pb-5">
                <div className="container pb-5">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: "500px;" }}>
                        <h1 className={`my-4 ${titleColor}`}>More Products</h1>
                    </div>
                    <div className="row g-4 py-5">
                        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                            <div className="case-item position-relative overflow-hidden rounded mb-2">
                                <img className="img-fluid" src={require("../img/education.jpg")} alt="" />
                                <Link className="case-overlay text-decoration-none" to="/products/academia">
                                    <small>Lighthouse Academia</small>
                                    <h5 className="lh-base text-light mb-3">Revolutionizing the education system
                                    </h5>
                                    <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                            <div className="case-item position-relative overflow-hidden rounded mb-2">
                                <img className="img-fluid" src={require("../img/EnterpriseAI.jpg")} alt="" />
                                <Link className="case-overlay text-decoration-none" to="/products/alma">
                                    <small>Alma INC</small>
                                    <h5 className="lh-base text-light mb-3">AI Powered Enterprise Management
                                    </h5>
                                    <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s" style={{ height: 200 }}>
                            <div className="case-item position-relative overflow-hidden rounded mb-2">
                                <img className="img-fluid" src={require("../img/project-3.jpg")} alt="" />
                                <Link className="case-overlay text-decoration-none" to="/products/research">
                                    <small>Lighthouse Research Institute</small>
                                    <h5 className="lh-base text-light mb-3">Advancing human capabilities</h5>
                                    <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* FAQ */}
            <div className="container-fluid my-5 py-4" id="FAQS">
                <div className="container py-5">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                        <div className="btn btn-sm border text-gold rounded-pill px-3 mb-3">FAQs</div>
                        <h1 className={`mb-4 ${titleColor}`}>Frequently Asked Questions</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="accordion" id="accordionFAQ1">
                                <div className="accordion-item wow fadeIn" data-wow-delay="0.1s">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            Where are you located?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionFAQ1">
                                        <div className="accordion-body text-center p-2">
                                            We are located in Nairobi, Kenya.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item wow fadeIn" data-wow-delay="0.2s">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How do i join the Lighthouse Community?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionFAQ1">
                                        <div className="accordion-body text-center p-2">
                                            Smile, create with family and friends, and dont forget to hit the like button
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item wow fadeIn" data-wow-delay="0.3s">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            What are some examples of projects you`ve done?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                        data-bs-parent="#accordionFAQ1">
                                        <div className="accordion-body text-center p-2">
                                            We are working with real estate companies to develop smart homes.
                                            Weve provided suggestions that have helped numerous companies grow
                                            We are working to achieve to achieve Net Zero by 2030
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item wow fadeIn" data-wow-delay="0.4s">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            What determines if a project will be presented to a corporation or individual?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                        data-bs-parent="#accordionFAQ1">
                                        <div className="accordion-body text-center p-2">
                                            Proof of concept and Net Positive Contribution
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="accordion" id="accordionFAQ2">
                                <div className="accordion-item wow fadeIn" data-wow-delay="0.5s">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            What type of Company are you?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                                        data-bs-parent="#accordionFAQ2">
                                        <div className="accordion-body text-center p-2">
                                            We are the first of our kind
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item wow fadeIn" data-wow-delay="0.6s">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            I’m on a strict budget. Do you have any low cost options?
                                        </button>
                                    </h2>
                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix"
                                        data-bs-parent="#accordionFAQ2">
                                        <div className="accordion-body text-center p-2">
                                            The Lighthouse Module has a beta tier which you can apply for and get for free
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item wow fadeIn" data-wow-delay="0.7s">
                                    <h2 className="accordion-header" id="headingSeven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            Can you tell me about Alma
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                                        data-bs-parent="#accordionFAQ2">
                                        <div className="accordion-body text-center p-2">
                                            Certainly. If you create a business profile on Alma, you get exposure, access to
                                            data analysis tools and generative AI.
                                            You even get your very own voice assistant that can schedule tasks and advise on
                                            operations
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item wow fadeIn" data-wow-delay="0.8s">
                                    <h2 className="accordion-header" id="headingEight">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                            What is your goal as a company
                                        </button>
                                    </h2>
                                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight"
                                        data-bs-parent="#accordionFAQ2">
                                        <div className="accordion-body text-center p-2">
                                            To provide products and services that impact the world positively and steer humanity
                                            forward
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Testimonials */}
            <div className="container-xxl py-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className={`btn btn-sm border rounded-pill ${textColor} px-3 mb-3`}>Testimonials</div>
                            <h1 className={`mb-4 ${titleColor}`}>Our Clients are our biggest supporters!</h1>
                            <p className={`mb-4 ${textColor}`}>If theres one thing our clients have in common, is that they maintain a smile,
                                through lifes challenges and tribulations.
                                Our desire is to add on to that smile, and make it broader as the days go by.
                            </p>
                        </div>
                        <div className="col-lg-7 wow fadeIn testimonial-container" data-wow-delay="0.5s">
                            <div className="owl-carousel testimonial-carousel border-end border-primary">
                                <div className="testimonial-item ps-5">
                                    <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                                    <p className={`fs-4 ${textColor}`}>Lighthouse is more than a company. Its a community, a family. Only one
                                        lighthouse</p>
                                    <div className="d-flex align-items-center">
                                        <img alt="" className="img-fluid flex-shrink-0 rounded-circle" src={require("../img/testimonial-3.jpg")}
                                            style={{ width: "60px", height: "60px" }} />
                                        <div className="ps-3">
                                            <h5 className={`mb-1 ${textColor}`}>David Njau</h5>
                                            <span className={`${textColor}`}>CEO Color & Drive</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item ps-5">
                                    <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                                    <p className={`fs-4 ${textColor}`}>In a world where so much is going on, Lighthouse gives us hope for a better
                                        tommorow. I am proud of what they do.</p>
                                    <div className="d-flex align-items-center">
                                        <img alt="" className="img-fluid flex-shrink-0 rounded-circle" src={require("../img/testimonial-1.jpg")}
                                            style={{ width: "60px", height: "60px" }} />
                                        <div className="ps-3">
                                            <h5 className={`mb-1 ${textColor}`}>Monicah Njoki</h5>
                                            <span className={`${textColor}`}>Customer Relations, Alma INC</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item ps-5">
                                    <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                                    <p className={`fs-4 ${textColor}`}>All good vibes and positive energy. Spirit of hard work present, and
                                        ambition to realize goals.</p>
                                    <div className="d-flex align-items-center">
                                        <img alt="" className="img-fluid flex-shrink-0 rounded-circle" src={require("../img/testimonial-2.jpg")}
                                            style={{ width: "60px", height: "60px" }} />
                                        <div className="ps-3">
                                            <h5 className={`mb-1 ${textColor}`}>Brian Njau</h5>
                                            <span className={`${textColor}`}>AI Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <FooterComponent />
        </div>
    )
}

export default HomeScreenComponent;