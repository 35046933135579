import { useEffect } from "react";
import FooterComponent from "../components/Footer";
import HeaderComponent from "../components/Header";
import NavigationComponent from "../components/Navigation";
import { isMobile } from 'react-device-detect';
import { Link } from "react-router-dom";

const ContactScreenComponent = () => {
    const LighthouseEmail = "corporationlighthouse@gmail.com"
    const EmailSubject = "Hello Lighthouse"

    useEffect(() => {
        const scrollPosition = window.scrollY;
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, scrollPosition);
        };
    }, []);

    return (
        <div>
            <NavigationComponent />
            <HeaderComponent title="Contact Us Today" />
            <div className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="row g-3 mb-5">
                            <div className="col-12">
                                {
                                    isMobile ? (
                                        <Link to={`tel:${+254759610553}`} className="btn btn-primary w-100 py-3">Call</Link>
                                    ) : (
                                        <Link to="/contact" className="btn btn-primary w-100 py-3">Call</Link>
                                    )
                                }
                            </div>
                            <div className="col-12">
                                <Link to={`mailto:${LighthouseEmail}?subject=${encodeURIComponent(EmailSubject)}`} className="btn btn-primary w-100 py-3">Email</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent />
        </div>
    )
}

export default ContactScreenComponent;