import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../contextapis/AppContext';

const NavigationComponent = () => {
    const location = useLocation()
    const path = location.pathname
    const { light, setLight } = useContext(AppContext)
    const textColor = light ? 'text-dark' : 'text-light'
    return (
        <div className='container-fluid' style={{ background: 'transparent' }}>
            <div className='container'>
                <nav className='navbar navbar-expand-lg navbar-dark'>
                    <a href='/' className='navbar-brand'>
                        <h4 className={`${textColor}`}>Lighthouse PLC</h4>
                    </a>
                    <button type='button' className='navbar-toggler ms-auto me-0' data-bs-toggle='collapse'
                        data-bs-target='#navbarCollapse'>
                        {
                            light ? (
                                <img src={require("../img/menu.png")} style={{ width: 40, height: 40, rotate: '180deg'}} alt="Toggle Menu Icon" />
                            ) : (
                                <img src={require("../img/menu-light.png")} style={{ width: 40, height: 40, rotate: '180deg'}} alt="Toggle Menu Icon" />
                            )
                        }
                    </button>
                    <div className='collapse navbar-collapse' id='navbarCollapse'>
                        <div className='navbar-nav ms-auto'>
                            <Link className={`nav-item nav-link ${path === '/' ? 'active' : textColor} `} to="/">Home</Link>
                            <Link className={`nav-item nav-link ${path.startsWith("/products") ? 'active' : textColor}`} to="/products">Products</Link>
                            <Link className={`nav-item nav-link ${path === '/family' ? 'active' : textColor}`} to="/family">Family</Link>
                            {/* <Link className={`nav-item nav-link ${path == '/papers' && 'active'}`} to="/papers">Papers</Link>
                            <Link className={`nav-item nav-link ${path == '/contact' && 'active'}`} to="/contact">Contact</Link> */}
                            <div className='nav-item dropdown'>
                                <Link to='#' className={`nav-link dropdown-toggle ${textColor}`} data-bs-toggle='dropdown'>More</Link>
                                <div className='dropdown-menu bg-light mt-2'>
                                    <Link onClick={() => setLight(!light)} to='#' className='dropdown-item'>{light ? 'Dark Theme' : 'Light Theme'}</Link>
                                    <Link to='/contact' className={`dropdown-item ${path === '/contact' ? 'active' : 'text-dark'}`}>Contact</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default NavigationComponent;