import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../contextapis/AppContext';

const HeaderComponent = ({title}:{title:string}) => {
    const {light} = useContext(AppContext)
    const textColor = light ? 'text-dark' : 'text-light'
    const location = useLocation()
    const path = location.pathname

    return (
        <div className='container-fluid hero-header pt-8' id='header'>
            <div className='container pt-5'>
                <div className='row g-5 py-5 my-5'>
                    <div className='col-lg-6 align-self-center text-center text-lg-start mb-lg-5'>
                    <h1 className={`display-4 mb-4 ${textColor} animated slideInRight`}>{title}</h1>
                        {
                            path === '/' && (
                                <>
                                    <p className={`text-white mb-4 ${textColor} animated slideInRight`}>Innovating products and ideas that advance humanity
                                    </p>
                                    <a href='#about' className='btn btn-dark py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight'>Read
                                        More</a>
                                    <a href="../pdfs/website.pdf" download="lighthouse-website" className='btn btn-dark py-sm-3 px-sm-5 rounded-pill animated slideInRight'>Download
                                        PDF</a>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderComponent;