import { useContext, useEffect } from "react";
import FooterComponent from "../components/Footer";
import HeaderComponent from "../components/Header";
import { AppContext } from "../contextapis/AppContext";
import NavigationComponent from "../components/Navigation";

const Alma = () => {
    const { light } = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'

    useEffect(() => {
        const scrollPosition = window.scrollY;
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, scrollPosition);
        };
    }, []);
    
    return (
        <>
            <NavigationComponent />
            <HeaderComponent title="Alma INC" />
            <div className="container-fluid feature pt-5">
                <div className="container pt-5">
                    <div className="col align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                        <h2 className={`mb-4 ${titleColor}`}>Enterprise Management Toolkit</h2>
                        <p className={`mb-4 ${textColor}`}>
                            If you create a business profile on Alma, you get exposure, access to
                            data analysis tools and generative AI.
                            You even get your very own voice assistant that can schedule tasks and advise on
                            operations
                        </p>
                    </div>
                    <div className="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                        <img className="img-fluid" src="../src/img/feature.png" alt="" />
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}

export default Alma;