import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../contextapis/AppContext";

const FooterComponent = () => {
    const {light} = useContext(AppContext)
    const titleColor = light ? 'text-dark' : 'text-light'
    const textColor = light ? 'text-dark-50' : 'text-light-50'
    const buttonOutline = light ? 'btn-outline-dark' : 'btn-outline-light'
    return (
        <div className="container-fluid text-white-50 footer pt-5">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                        <Link to="#" className="d-inline-block mb-3">
                            <h4 className={`${titleColor}`}>The Lighthouse Corporation</h4>
                        </Link>
                        <p className={`mb-0 ${textColor}`}>On my path to enlightenment, I shall promote peace and well-being
                        </p>
                    </div>
                    <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                        <h5 className={`mb-4 ${titleColor}`}>Get In Touch</h5>
                        <p className={`${textColor}`}><i className={`fa ${textColor} fa-map-marker-alt me-3`}></i>Nairobi, Kenya</p>
                        <p className={`${textColor}`}><i className={`fa ${textColor} fa-phone-alt me-3`}></i>+254 759 610 553</p>
                        <p className={`${textColor}`}><i className={`fa ${textColor} fa-envelope me-3`}></i>corporationlighthouse@gmail.com</p>
                        <div className={`d-flex pt-2 ${textColor}`}>
                            <a className={`btn mt-2 ${buttonOutline} btn-social`} href="/"><i className="fab fa-twitter"></i></a>
                            <a className={`btn mt-2 ${buttonOutline} btn-social`} href="/"><i className="fab fa-facebook-f"></i></a>
                            <a className={`btn mt-2 ${buttonOutline} btn-social`} href="/"><i className="fab fa-youtube"></i></a>
                            <a className={`btn mt-2 ${buttonOutline} btn-social`} href="/"><i className="fab fa-instagram"></i></a>
                            <a className={`btn mt-2 ${buttonOutline} btn-social`} href="/"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                        <h5 className={`${titleColor} mb-4`}>Popular Links</h5>
                        <Link className={`${textColor} btn btn-link`} to="/#about">About Us</Link>
                        <Link className={`${textColor} btn btn-link`} to="/contact">Contact Us</Link>
                        <Link className={`${textColor} btn btn-link`} to="#">Privacy Policy</Link>
                        <Link className={`${textColor} btn btn-link`} to="#">Terms & Condition</Link>
                        <Link className={`${textColor} btn btn-link`} to="#">Careers</Link>
                    </div>
                    <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                        <h5 className={`${titleColor} mb-4`}>Vision 11</h5>
                        <Link className={`${textColor} btn btn-link`} to="/products/vision-11/data">Data</Link>
                        <Link className={`${textColor} btn btn-link`} to="/products/vision-11/sustainability">Sustainability</Link>
                        <Link className={`${textColor} btn btn-link`} to="/products/vision-11/automation">Process Automation</Link>
                        <Link className={`${textColor} btn btn-link`} to="/products/vision-11/science">Science and Engineering</Link>
                    </div>
                </div>
            </div>
            <div className="container wow fadeIn" data-wow-delay="0.1s">
                <div className="copyright">
                    <div className="row">
                        <div className={`col-md-6 ${textColor} text-center text-md-start mb-3 mb-md-0`}>
                            &copy; <a className={`${textColor} border-bottom`} href="/">Lighthouse</a>, All Right Reserved
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <div className="footer-menu">
                                <Link className={`${textColor}`} to="/">Home</Link>
                                <Link className={`${textColor}`} to="/">Cookies</Link>
                                <Link className={`${textColor}`} to="/">Help</Link>
                                <Link className={`${textColor}`} to="#FAQS">FAQs</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Back to Top */}
            <button title="Scroll To Top" onClick={() => window.scrollTo(0, 0)} className="btn btn-lg btn-primary btn-lg-square back-to-top pt-2"><i className="bi bi-arrow-up"></i></button>
        </div>
    )
}

export default FooterComponent;