import NavigationComponent from "../components/Navigation";
import { Link } from "react-router-dom";

const FourScreenComponent = () => {
    return (
        <div>
            <NavigationComponent />
            <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container text-center py-5">
                    <div className="row justify-content-center py-5">
                        <div className="col-lg-6">
                            <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                            <h1 className="display-1 text-danger">404</h1>
                            <h1 className="mb-4 text-danger">Page Not Found</h1>
                            <Link className="btn btn-primary rounded-pill py-3 px-5" to="/">Go Back To Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FourScreenComponent;