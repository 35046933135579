import {ReactNode, Dispatch, useState, createContext, SetStateAction} from 'react';

type AppContextType = {
    light: boolean,
    setLight:  Dispatch<SetStateAction<boolean>>
}

export const AppContext = createContext({} as AppContextType)

export const AppContextProvider = ({children}:{children: ReactNode})=>{
    const [light, setLight] = useState(true)
    return(
        <AppContext.Provider value={{
            light, setLight
        }}>
            {children}
        </AppContext.Provider>
    )
}
